import axios from 'axios'

import store from '@/stores'

export function useUser() {
    const fetchIpAddress = async (url: string) => {
        try {
            const response = await axios.get(url)
            return response.data.ip || response.data.ipString
        } catch (error) {
            console.error(`Error fetching IP address from ${url}:`, (error as any)?.message)
            return null
        }
    }

    const getUserIPAddress = async () => {
        const apiEndpoints = ['https://api.ipify.org?format=json', 'https://api.bigdatacloud.net/data/client-ip']

        for (const url of apiEndpoints) {
            const ipAddress = await fetchIpAddress(url)
            if (ipAddress) {
                store.state.ip = ipAddress
                return ipAddress
            }
        }

        console.error('Failed to retrieve user IP address from any source')
        return null
    }

    return {
        getUserIPAddress
    }
}
