import { createRouter, createWebHistory } from 'vue-router'

import { authGuard } from './authGuard'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        // {
        //   path: '/',
        //   name: "magic",
        //   component: () =>
        //     import(/* webpackChunkName "magic" */ "@/views/MagicBarSearch.vue"),
        //   meta: {
        //     authRequired: false,
        //     layout: "AppLayoutApp",
        //     title: "Magic Bar Search",
        //   },
        //   strict: true,
        // },
        {
            path: '/',
            name: 'magic',
            component: () => import(/* webpackChunkName "magic" */ '@/views/Home.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Home'
            },
            strict: true
        },
        {
            path: '/trip/:trip_id',
            name: 'trip',
            component: () => import(/* webpackChunkName "magic" */ '@/views/Trip.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Trip'
            },
            strict: true
        },
        {
            path: '/trips',
            name: 'trips',
            component: () => import(/* webpackChunkName "magic" */ '@/views/Trips.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Trips'
            },
            strict: true
        },
        {
            path: '/trip/:trip_id/hotels',
            name: 'hotel search',
            component: () => import(/* webpackChunkName "magic" */ '@/views/HotelSearch.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Hotel Search'
            },
            strict: true
        },
        {
            path: '/trip/:trip_id/hotels/:city/:name',
            name: 'mainHotels',
            component: () => import(/* webpackChunkName "confirm" */ '@/views/MainHotelPage.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Hotel Page'
            },
            strict: true,
            children: [
                {
                    path: '',
                    name: 'hotels',
                    component: () => import(/* webpackChunkName "hotelBooking" */ '@/views/HotelPage.vue'),
                    meta: {
                        authRequired: false,
                        layout: 'AppLayoutApp',
                        title: 'Hotel Page'
                    },
                    strict: true
                },
                {
                    path: 'book',
                    name: 'hotelBooking',
                    component: () => import(/* webpackChunkName "hotelBooking" */ '@/views/HotelBooking.vue'),
                    meta: {
                        authRequired: false,
                        layout: 'AppLayoutApp',
                        title: 'Book Stay'
                    },
                    strict: true
                }
            ]
        },
        {
            path: '/internal/sessions',
            name: 'internal',
            component: () => import(/* webpackChunkName "searchSessions" */ '@/views/SearchSessions.vue'),
            meta: {
                authRequired: true,
                layout: 'AppLayoutApp',
                title: 'Internal'
            },
            strict: true
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName "auth" */ '@/views/Auth.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Login'
            },
            strict: true
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import(/* webpackChunkName "auth" */ '@/views/SignUp.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Sign Up'
            },
            strict: true
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import(/* webpackChunkName "auth" */ '@/views/LogOut.vue'),
            meta: {
                authRequired: true,
                layout: 'AppLayoutApp',
                title: 'Logout'
            },
            strict: true
        },
        {
            path: '/verify',
            name: 'verify',
            component: () => import(/* webpackChunkName "auth" */ '@/views/Verify.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Verify'
            },
            strict: true
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import(/* webpackChunkName "auth" */ '@/views/ResetPassword.vue'),
            meta: {
                layout: 'AppLayoutApp',
                title: 'Reset Password'
            },
            strict: true
        },
        {
            path: '/manage',
            name: 'manage-booking',
            component: () => import(/* webpackChunkName "manage-booking" */ '@/views/ManageBooking.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Manage Booking'
            },
            strict: true
        },
        {
            path: '/help',
            name: 'help',
            component: () => import(/* webpackChunkName "manage-booking" */ '@/views/Help.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Help & Support'
            },
            strict: true
        },
        {
            path: '/learn',
            name: 'learn',
            component: () => import(/* webpackChunkName "learn" */ '@/views/LearnView.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Learn'
            },
            strict: true
        },
        {
            path: '/confirm',
            name: 'confirm',
            component: () => import(/* webpackChunkName "confirm" */ '@/views/Confirm.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Sprout Magic Confirm'
            },
            strict: true
        },
        {
            path: '/restaurants',
            name: 'restaurants',
            component: () => import(/* webpackChunkName "restaurants" */ '@/views/Restaurants.vue'),
            meta: {
                layout: 'AppLayoutApp',
                title: 'Restaurants'
            },
            strict: true
        },
        {
            path: '/admin/metrics',
            name: 'metrics',
            component: () => import(/* webpackChunkName "confirm" */ '@/views/Metrics.vue'),
            meta: {
                authRequired: true,
                layout: 'AppLayoutApp',
                title: 'Magic Metrics Dashboard'
            },
            strict: true
        },
        {
            path: '/yestheory',
            name: 'yestheory',
            component: () => import(/* webpackChunkName "confirm" */ '@/views/Giveaway.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Magic x Yes Theory'
            },
            props: { promotionSlug: 'yestheory' },
            strict: true
        },
        {
            path: '/yestheorygiveawayterms',
            name: 'yestheorygiveawayterms',
            component: () => import(/* webpackChunkName "confirm" */ '@/views/YesTheoryGiveawayTerms.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Magic x Yes Theory Terms'
            },
            strict: true
        },
        {
            path: '/drewbinsky',
            name: 'drewbinsky',
            component: () => import(/* webpackChunkName "confirm" */ '@/views/Giveaway.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Magic x Drew Binsky'
            },
            props: { promotionSlug: 'drewbinsky' },
            strict: true
        },
        {
            path: '/drewbinskygiveawayterms',
            name: 'drewbinskygiveawayterms',
            component: () => import(/* webpackChunkName "confirm" */ '@/views/DrewBinskyGiveawayTerms.vue'),
            meta: {
                authRequired: false,
                layout: 'AppLayoutApp',
                title: 'Magic x Drew Binsky Terms'
            },
            strict: true
        },
        {
            path: '/:pathMatch(.*)*',
            component: () => import(/* webpackChunkName "notfound" */ '@/views/PathNotFoundView.vue')
        }
        // {
        //   path: '/mobile-not-supported',
        //   name: 'MobileNotSupported',
        //   meta: {
        //     authRequired: false,
        //     layout: "AppLayoutApp",
        //     title: "Mobile Not Supported",
        //   },
        //   component: () =>
        //     import(/* webpackChunkName "mobilenotsupported" */ "@/views/MobileNotSupported.vue"),
        // },
    ]
})

router.beforeEach(authGuard)

router.onError((err) => {
    console.error('err', err)
    router.push('/404')
})

export default router
