import './assets/main.css'
import './assets/style.css'

import * as amplitude from '@amplitude/analytics-browser'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/vue'
import LogRocket from 'logrocket'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import AppLayout from '@/layouts/AppLayoutApp.vue'

import App from './App.vue'
import router from './router'
import store from './stores'

library.add(fas)

const app = createApp(App)

app.use(createPinia())
app.use(router)

const currAppState = localStorage?.getItem('appState')
amplitude.init(import.meta.env.VITE_APP_AMPLITUDE_API_KEY, {
    userId: currAppState ? JSON.parse(currAppState)?.user?.id : null
})

const urlParams = new URLSearchParams(window.location.search)
const creatorReferral = urlParams.get('r')
if (creatorReferral) {
    store.setCreatorReferral(creatorReferral)
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('creator_referrer', creatorReferral)
    amplitude.identify(identifyEvent)
}

if (import.meta.env.MODE === 'production') {
    LogRocket.init('sprout/magic')
    LogRocket.getSessionURL((sessionURL) => {
        amplitude.track({
            event_type: 'New Logrocket Session',
            user_id: store.state.user?.id,
            event_properties: {
                creator_referrer: creatorReferral || null,
                logrocket_session_url: sessionURL
            }
        })
    })
}

Sentry.init({
    app: app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_BACKEND_URL],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_APP_ENV
})

app.config.errorHandler = function (err: any, vm, info) {
    // Handle the error globally
    console.error('Global error:', err)
    console.error('Vue instance:', vm)
    console.error('Error info:', info)

    return null
}

app.directive('scroll-fade', {
    mounted(el) {
        const observer = new IntersectionObserver(
            ([entry]) => {
                entry.isIntersecting ? el.classList.add('opacity-100') : el.classList.remove('opacity-100')
            },
            { threshold: 0.1 }
        )
        observer.observe(el)
    }
})

app.directive('on-render', {
    mounted(el, binding) {
        binding.value()
    }
})

app.component('font-awesome-icon', FontAwesomeIcon).component('AppLayout', AppLayout).mount('#app')
